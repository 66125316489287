import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MyappsComponent } from './myapps/myapps.component';
import { MyprofileComponent } from './myprofile/myprofile.component';
import { LayoutComponent } from './layout/layout.component';
import { SyncfusionModule } from './syncfusion/syncfusion.module';
import { AuthModule } from '@auth0/auth0-angular';
import { LoadingComponent } from './loading/loading.component';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { FooterComponent } from './footer/footer.component';
import { MarkdownModule } from 'ngx-markdown';
import { PrivacyComponent } from './pages/privacy/privacy.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MyappsComponent,
    MyprofileComponent,
    LayoutComponent,
    LoadingComponent,
    FooterComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SyncfusionModule,
    HttpClientModule,
    MarkdownModule.forRoot(),
    AuthModule.forRoot({
      domain: environment.domain,
      clientId: environment.clientId,
      authorizationParams: {
        redirect_uri: window.location.origin,
        audience: environment.audience,
        scope: 'openid profile email offline_access'
      },
      cacheLocation: 'memory', 
      useRefreshTokens: true,
      useRefreshTokensFallback: false, 
    })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
